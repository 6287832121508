(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/selector.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/selector.js');

'use strict';

const {
  gameTypes
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const findTopp5 = racecard => {
  const findRace = race => race.draws.find(element => element.gameType === gameTypes.TOPP5);
  const meeting = racecard.filter(meeting => meeting.raceSummaries.filter(findRace)[0])[0];
  if (meeting) {
    const raceSummary = meeting.raceSummaries.filter(findRace)[0];
    if (raceSummary) {
      return [meeting, raceSummary];
    }
  }
  return [];
};
const mapRacecard = function () {
  let racecard = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let callback = arguments.length > 1 ? arguments[1] : undefined;
  return racecard.map(meeting => meeting.raceSummaries.map(raceSummary => callback(meeting, raceSummary)));
};
if (svs.isServer) {
  module.exports = {
    findTopp5
  };
} else {
  svs.racing.raceService.utils.selector = {
    mapRacecard
  };
}

 })(window);