(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/prescript.js');

'use strict';

svs = svs || {};
svs.racing = svs.racing || {};
svs.racing.raceService = svs.racing.raceService || {};
svs.racing.raceService.utils = svs.racing.raceService.utils || {};


 })(window);