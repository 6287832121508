(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/url.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/url.js');

'use strict';

const {
  gameTypes,
  menuItems,
  XPERTEN
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const strings = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').strings : svs.racing.common.strings;
const createPlayPath = _ref => {
  let {
    day,
    trackName,
    raceNumber,
    gameType,
    xpert,
    menuItem
  } = _ref;
  let url = '';
  const queries = [];
  url += "/".concat(trackName);
  url += "/lopp-".concat(raceNumber);
  if (gameType) {
    const gameTypeName = strings.getProductUrlName(gameType);
    url += "/".concat(gameTypeName);
    if (xpert) {
      url += "/".concat(XPERTEN);
    }
  }
  url += "/".concat(day);

  if (!svs.isServer) {
    const searchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of searchParams.entries()) {
      if (key === 'menuItem' && searchParams.has('flik') || key === 'menuItem') {
        continue;
      }
      queries.push("".concat(key, "=").concat(value));
    }
  }

  if (menuItem && menuItem !== menuItems.COUPON) {
    queries.push("menuItem=".concat(menuItem));
  }
  if (queries.length > 0) {
    url += "?".concat(queries.join('&'));
  }
  return url;
};
const createTopp5Path = () => "/".concat(gameTypes.TOPP5);
if (svs.isServer) {
  module.exports = {
    createPlayPath
  };
} else {
  svs.racing.raceService.utils.url = {
    createPlayPath,
    createTopp5Path
  };
}

 })(window);