(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/participants-meta-fields.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/participants-meta-fields.js');
"use strict";


const {
  infoTypes,
  gameTypes,
  gaits
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const participantsMetaProperties = {
  distribution: {
    type: infoTypes.PERCENT,
    header: 'Folkets vinnare',
    sortOrder: 10
  },
  oddsLatest: {
    type: infoTypes.ODDS_LATEST,
    header: 'Vinnarodds',
    sortOrder: 20
  },
  oddsRef: {
    type: infoTypes.ODDS_REF,
    header: '',
    sortOrder: 30
  },
  showOdds: {
    type: infoTypes.SHOW_ODDS,
    header: 'Platsodds',
    sortOrder: 31
  },
  startGate: {
    type: infoTypes.START_GATE,
    gait: gaits.GALLOP,
    header: 'Start-boxnr.',
    sortOrder: 40
  },
  lastPerformance: {
    type: infoTypes.LAST_PERFORMANCE,
    header: 'Placering senaste starter',
    sortOrder: 50,
    hasExplanation: true
  },
  trottingShoes: {
    type: infoTypes.SHOES,
    gait: gaits.TROTTING,
    header: 'Skor',
    sortOrder: 60,
    hasExplanation: true
  },
  driverTotalWeight: {
    type: infoTypes.WEIGHT,
    gait: gaits.GALLOP,
    header: 'Ridvikt kg',
    sortOrder: 70,
    hasExplanation: false
  },
  blinkers: {
    type: infoTypes.BLINKERS,
    gait: gaits.GALLOP,
    header: 'Utrustning',
    sortOrder: 80,
    hasExplanation: true
  },
  additionalDistance: {
    type: infoTypes.DISTANCE,
    gait: gaits.TROTTING,
    header: 'Tilläggsdistans',
    sortOrder: 90,
    hasExplanation: false
  },
  ioDistribution: {
    type: infoTypes.IO_DISTRIBUTION,
    header: 'Insatsfördelning',
    getHeaderByGameType: {
      [gameTypes.VINNARE]: 'Vinnare %',
      [gameTypes.PLATS]: 'Plats %',
      [gameTypes.TOPP2]: 'Duo %',
      [gameTypes.RX2IO]: 'Duo Rak %',
      [gameTypes.RX2P4]: '2 av 4 %',
      [gameTypes.RX2P]: '2 av 3 %',
      [gameTypes.TOPP5]: 'High Five %',
      [gameTypes.RX4IO]: 'High 4 %',
      [gameTypes.RX3IOAO]: 'High 3 %',
      [gameTypes.RX3AO]: 'Trippel %',
      [gameTypes.RX3IO]: 'Trippel Rak %'
    },
    sortOrder: 100,
    hasExplanation: false
  }
};
if (svs.isServer) {
  module.exports = {
    participantsMetaProperties
  };
} else {
  svs.racing.raceService.utils.participantsMetaProperties = participantsMetaProperties;
}

 })(window);