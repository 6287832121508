(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/parse.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/parse.js');


'use strict';

const {
  drawStates,
  raceDrawStates,
  raceStatuses,
  finishPlaceReasons,
  gameTypes,
  gaits,
  raceDisciplines,
  startTypes,
  raceDirections,
  trackConditions
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const format = svs.isServer ? require('trinidad-core').core.components.require('utils', 'format').api : svs.utils.format;
const logger = svs.isServer ? require('trinidad-core').core.logger('component:racing:race-service:parse') : svs.core.log.getLogger('component:racing:race-service:parse');
function parseDistance(distance, distanceUnit) {
  if (!distance || !distanceUnit) {
    return undefined;
  }
  const firstLetterOfUnit = distanceUnit.charAt(0).toLowerCase();
  return "".concat(distance, " ").concat(firstLetterOfUnit);
}
function parseDrawState(drawState) {
  switch (drawState) {
    case 'Undefined':
      return drawStates.UNDEFINED;
    case 'Defined':
      return drawStates.DEFINED;
    case 'Opened':
      return drawStates.OPENED;
    case 'Regclosed':
      return drawStates.REGCLOSED;
    case 'Cancelclosed':
      return drawStates.CANCELCLOSED;
    case 'ResultEntered':
      return drawStates.RESULT_ENTERED;
    case 'ResultVerified':
      return drawStates.RESULT_VERIFIED;
    case 'WincountStarted':
      return drawStates.WINCOUNT_STARTED;
    case 'WincountFailed':
      return drawStates.WINCOUNT_FAILED;
    case 'WincountDone':
      return drawStates.WINCOUNT_DONE;
    case 'WinvaluesEntered':
      return drawStates.WINVALUES_ENTERED;
    case 'WinvaluesVerified':
      return drawStates.WINVALUES_VERIFIED;
    case 'WingenStarted':
      return drawStates.WINGEN_STARTED;
    case 'WingenFailed':
      return drawStates.WINGEN_FAILED;
    case 'WingenDone':
      return drawStates.WINGEN_DONE;
    case 'WinpayoutStarted':
      return drawStates.WINPAYOUT_STARTED;
    case 'WinpayoutFailed':
      return drawStates.WINPAYOUT_FAILED;
    case 'PublishStarted':
      return drawStates.PUBLISH_STARTED;
    case 'PublishFailed':
      return drawStates.PUBLISH_FAILED;
    case 'PublishDone':
      return drawStates.PUBLISH_DONE;
    case 'Finalized':
      return drawStates.FINALIZED;
    default:
      logger.debug("Unknown drawStateId: ".concat(drawState));
      return undefined;
  }
}
function parseRaceDrawState(raceDrawState) {
  switch (raceDrawState) {
    case 'Undefined':
      return raceDrawStates.UNDEFINED;
    case 'Defined':
      return raceDrawStates.DEFINED;
    case 'Opened':
      return raceDrawStates.OPENED;
    case 'Regclosed':
      return raceDrawStates.REGCLOSED;
    case 'Cancelclosed':
      return raceDrawStates.CANCELCLOSED;
    case 'ResultEntered':
      return raceDrawStates.RESULT_ENTERED;
    case 'ResultVerified':
      return raceDrawStates.RESULT_VERIFIED;
    case 'Finalized':
      return raceDrawStates.FINALIZED;
    default:
      logger.debug("Unknown raceDrawState: ".concat(raceDrawState));
      return undefined;
  }
}
function parseRaceStatus(raceStatus) {
  switch (raceStatus) {
    case 'Undefined':
      return raceStatuses.UNDEFINED;
    case 'Start':
      return raceStatuses.START;
    case 'Stopped':
      return raceStatuses.STOPPED;
    case 'RunnersReady':
      return raceStatuses.RUNNERS_READY;
    case 'JockeyChange':
      return raceStatuses.JOCKEY_CHANGE;
    case 'RunnersOnTrack':
      return raceStatuses.RUNNERS_ON_TRACK;
    case 'StartIn3Minutes':
      return raceStatuses.START_IN_3_MINUTES;
    case 'RunnersWaitingForStart':
      return raceStatuses.RUNNERS_WAITING_FOR_START;
    case 'FalseStart':
      return raceStatuses.FALSE_START;
    case 'Started':
      return raceStatuses.STARTED;
    case 'ResultEntered':
      return raceStatuses.RESULT_ENTERED;
    case 'ResultEnteredPhoto':
      return raceStatuses.RESULT_ENTERED_PHOTO;
    case 'ResultEnteredValidated':
      return raceStatuses.RESULT_ENTERED_VALIDATED;
    case 'ResultOfficial':
      return raceStatuses.RESULT_OFFICIAL;
    case 'ResultOfficialPhoto':
      return raceStatuses.RESULT_OFFICIAL_PHOTO;
    case 'RaceFinalized':
      return raceStatuses.RACE_FINALIZED;
    case 'Cancelled':
      return raceStatuses.CANCELLED;
    default:
      logger.debug("Unknown raceStatus: ".concat(raceStatus));
      return undefined;
  }
}
function parseGameType(draw) {
  switch (draw.userProduct) {
    case 'Rx5io':
      return gameTypes.TOPP5;
    case 'Rx4io':
      return gameTypes.RX4IO;
    case 'Rx3ioao':
      return gameTypes.RX3IOAO;
    case 'Rx2ao':
      return gameTypes.TOPP2;
    case 'Rx2io':
      return gameTypes.RX2IO;
    case 'Rx2p':
      return gameTypes.RX2P;
    case 'Rx2p4':
      return gameTypes.RX2P4;
    case 'Rx1w':
      return gameTypes.VINNARE;
    case 'Rx1p':
      return gameTypes.PLATS;
    case 'Rx3ao':
      return gameTypes.RX3AO;
    case 'Rx3io':
      return gameTypes.RX3IO;
    default:
      return undefined;
  }
}
function parseGait(gait) {
  switch (gait) {
    case 'Gallop':
      return gaits.GALLOP;
    case 'Trotting':
      return gaits.TROTTING;
    default:
      logger.debug("Unknown gait: ".concat(gait));
      return undefined;
  }
}
function parseRaceDiscipline(raceDiscipline) {
  switch (raceDiscipline) {
    case 'Flat':
      return raceDisciplines.FLAT;
    case 'Hurdles':
      return raceDisciplines.HURDLES;
    case 'Trotting':
      return raceDisciplines.TROTTING;
    case 'Steeplechase':
      return raceDisciplines.STEEPLECHASE;
    case 'Obstacle':
      return raceDisciplines.OBSTACLE;
    case 'Harnessed':
      return raceDisciplines.HARNESSED;
    case 'Cross':
      return raceDisciplines.CROSS;
    case 'Ridden':
      return raceDisciplines.RIDDEN;
    default:
      logger.debug("Unknown raceDiscipline: ".concat(raceDiscipline));
      return undefined;
  }
}
function parseStartType(startType) {
  switch (startType) {
    case 'Undefined':
      return undefined;
    case 'Auto':
      return startTypes.AUTO;
    case 'Volt':
      return startTypes.VOLT;
    case 'Stalls':
      return startTypes.STALLS;
    default:
      logger.debug("Unknown startType: ".concat(startType));
      return undefined;
  }
}
function parseFinishPlaceReason(reason) {
  switch (reason) {
    case 'Undefined':
      return finishPlaceReasons.UNDEFINED;
    case 'Disqualified':
      return finishPlaceReasons.DISQUALIFIED;
    case 'Beaten':
      return finishPlaceReasons.BEATEN;
    case 'TurnedBack':
      return finishPlaceReasons.TURNED_BACK;
    case 'Stopped':
      return finishPlaceReasons.STOPPED;
    case 'RunOut':
      return finishPlaceReasons.RUN_OUT;
    case 'Distanced':
      return finishPlaceReasons.DISTANCED;
    case 'RemainedAtThePost':
      return finishPlaceReasons.REMAINED_AT_THE_POST;
    case 'Fallen':
      return finishPlaceReasons.FALLEN;
    case 'Finished':
      return finishPlaceReasons.FINISHED;
    default:
      logger.debug("Unknown finishPlaceReason: ".concat(reason));
      return undefined;
  }
}
function parseDirection(direction) {
  switch (direction.toLowerCase()) {
    case 'left':
      return raceDirections.COUNTERCLOCKWISE;
    case 'right':
      return raceDirections.CLOCKWISE;
    default:
      logger.debug("Unknown direction: ".concat(direction));
      return undefined;
  }
}
function parseCurrency(currency) {
  return currency ? format.Currency(currency) : undefined;
}
function parseTrackCondition(trackCondition) {
  switch (trackCondition.toLowerCase()) {
    case 'souple':
    case 'soft':
      return trackConditions.SOFT;
    case 'bon souple':
    case 'good to soft':
    case 'goodtosoft':
      return trackConditions.GOOD_TO_SOFT;
    case 'très souple':
    case 'very soft':
    case 'verysoft':
      return trackConditions.VERY_SOFT;
    case 'bon':
    case 'good':
      return trackConditions.GOOD;
    case 'très léger':
    case 'hard':
      return trackConditions.HARD;
    case 'collant':
    case 'holding':
      return trackConditions.HOLDING;
    case 'lourd':
    case 'heavy':
      return trackConditions.HEAVY;
    case 'très lourd':
    case 'very heavy':
    case 'veryheavy':
      return trackConditions.VERY_HEAVY;
    case 'bon léger':
    case 'good to firm':
    case 'goodtofirm':
      return trackConditions.GOOD_TO_FIRM;
    case 'sec':
    case 'fast':
      return trackConditions.FAST;
    case 'assez souple':
    case 'quite soft':
    case 'quitesoft':
      return trackConditions.QUITE_SOFT;
    case 'profond':
    case 'yielding':
      return trackConditions.YIELDING;
    case 'léger':
    case 'firm':
      return trackConditions.FIRM;
    case 'psf lente':
      return trackConditions.PSF_LENTE;
    case 'psf rapide':
      return trackConditions.PSF_RAPIDE;
    case 'psf standard':
      return trackConditions.PSF_STANDARD;
    case 'psf très rapide':
      return trackConditions.PSF_TRES_RAPIDE;
    default:
      logger.debug("Unknown trackCondition: ".concat(trackCondition));
      return undefined;
  }
}
const exported = {
  parseDistance,
  parseDrawState,
  parseGait,
  parseRaceDrawState,
  parseRaceStatus,
  parseGameType,
  parseRaceDiscipline,
  parseStartType,
  parseCurrency,
  parseFinishPlaceReason,
  parseDirection,
  parseTrackCondition
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.raceService.utils.parse = exported;
}

 })(window);