(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/race-service.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/race-service.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  normalizeDistribution,
  normalizeJackpots,
  normalizeMostplayed,
  normalizeRace,
  normalizeRacecard,
  normalizeRacecardHot,
  normalizeRacecardNext,
  normalizeWinOdds,
  normalizeShowOdds,
  normalizeTips,
  normalizeTurnover,
  normalizeXperten,
  normalizeQuinellaOdds,
  normalizePressRatings
} = svs.racing.raceService.utils.normalize;
const {
  parseISO,
  isValid,
  format
} = dateFns;
const {
  resolveTimeZone
} = svs.components.lbUtils.helpers.formatDate;
const {
  gametypeHasIoDistribution
} = svs.racing.common.util;
const {
  subProductIdToGameType,
  gameTypes
} = svs.racing.common.constants;
const logger = svs.core.log.getLogger('component:racing:race-service');
const fetchRacecard = (day, callback) => {
  if (!day || !isValid(new Date(day))) {
    day = format(resolveTimeZone(new Date()), 'yyyy-MM-dd');
  }
  const options = {
    method: 'GET',
    path: "/racing/1/racecard/".concat(day)
  };
  svs.core.jupiter.call(options, response => {
    callback(null, normalizeRacecard(response));
  }, error => {
    callback(error);
  });
};
const fetchRacecardHot = callback => {
  const options = {
    method: 'GET',
    path: "/racing/1/racecard/hot"
  };
  svs.core.jupiter.call(options, response => {
    callback(null, normalizeRacecardHot(response));
  }, error => {
    callback(error);
  });
};
const multifetchMetaInfo = (raceId, userProducts, callback) => {
  if (raceId === 0) {
    logger.error('raceId is 0, most likely because of Meeting 14 or 15. No meta info has been requested');
    callback(null, []);
    return;
  }
  if (!raceId || !userProducts) {
    throw new Error("Missing required parameter. Got raceId: ".concat(raceId, ", userProducts: ").concat(userProducts));
  }
  const urls = [];
  urls.push("/racing/1/race/".concat(raceId, "/winodds"));
  urls.push("/racing/1/race/".concat(raceId, "/showodds"));
  userProducts.forEach(userProduct => {
    urls.push("/racing/2/race/".concat(raceId, "/distribution?userProduct=").concat(userProduct));
  });
  for (let i = 0; i < urls.length; i++) {
    urls[i] = encodeURIComponent(urls[i]);
  }
  const multifetchUrl = "/multifetch?urls=".concat(urls.join('|'));
  const options = {
    method: 'GET',
    path: multifetchUrl
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error.message);
      return;
    }
    for (let i = 0; i < response.responses.length; i++) {
      const element = response.responses[i];
      if (element.error) {
        logger.info('API Returned error', element.error);
      }
    }
    const normalizedResponses = {};

    if (!response.responses[0].error) {
      normalizedResponses.winodds = normalizeWinOdds(response.responses[0].race);
    }

    if (!response.responses[1].error) {
      normalizedResponses.showodds = normalizeShowOdds(response.responses[1].race);
    }

    const distributions = [];
    for (let i = 0; i < userProducts.length; i++) {
      if (!response.responses[i + 2].error) {
        distributions.push(normalizeDistribution(response.responses[i + 2].distribution, response.responses[i + 2].userProduct));
      }
    }
    normalizedResponses.distributions = distributions;
    callback(null, normalizedResponses);
  }, error => {
    callback(error);
  });
};
const fetchRace = (day, trackName, raceNumber, callback) => {
  const parsedDate = parseISO(day);
  if (!trackName) {
    throw new Error('Missing requires parameter trackName');
  } else if (isNaN(raceNumber)) {
    throw new Error('Parameter raceNumber must be a number');
  } else if (!isValid(parsedDate)) {
    throw new Error('Parameter day must be a parsable date string');
  }
  raceNumber = parseInt(raceNumber, 10);
  const formattedDay = format(parsedDate, 'yyyy-MM-dd');
  const options = {
    method: 'GET',
    path: "/racing/1/race/date/".concat(formattedDay, "?pathName=").concat(trackName, "&raceNumber=").concat(raceNumber)
  };
  svs.core.jupiter.call(options, response => {
    if (response.race) {
      const raceId = parseInt(response.race.raceId, 10);
      const raceProducts = ['RX1W'];
      response.race.draws.forEach(draw => {
        if (gametypeHasIoDistribution(subProductIdToGameType[draw.userProduct.toUpperCase()])) {
          raceProducts.push(draw.userProduct.toUpperCase());
        }
      });
      if (response.race) {
        multifetchMetaInfo(raceId, raceProducts, (error, responses) => {
          if (error) {
            throw new Error("Error when fetching winodds for raceId ".concat(raceId, ". Error: ").concat(error));
          }
          const {
            winodds,
            distributions,
            showodds
          } = responses;
          callback(null, normalizeRace(response.race, winodds, distributions, showodds));
        });
      }
    } else {
      callback(response.error.message);
    }
  }, error => {
    callback(error);
  });
};
const fetchXpert = (drawNumber, xpertenSize, callback) => {
  if (drawNumber && xpertenSize) {
    const url = "/racing/3/xpert/picks?drawNumber=".concat(drawNumber, "&rows=").concat(xpertenSize);
    svs.core.jupiter.post(url, response => {
      callback(undefined, normalizeXperten(response));
    }, error => {
      callback(error.responseJSON.error);
    });
  }
};
const fetchXpertCustom = (drawNumber, xpertenSize, customPicks, callback) => {
  if (drawNumber && xpertenSize) {
    const url = "/racing/3/xpert/picks?drawNumber=".concat(drawNumber, "&rows=").concat(xpertenSize);
    svs.core.jupiter.post({
      path: url,
      data: {
        picks: customPicks
      }
    }, response => {
      callback(undefined, normalizeXperten(response));
    }, error => {
      callback(error.responseJSON.error);
    });
  }
};
const fetchRacecardNext = callback => {
  const options = {
    method: 'GET',
    path: "/racing/1/racecard/next"
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeRacecardNext(response));
    }
  }, error => {
    callback(error);
  });
};
const fetchRaceNext = finalCallback => {
  async.waterfall([callback => {
    fetchRacecardNext(callback);
  }, (nextRacecardResponse, callback) => {
    fetchRace(nextRacecardResponse.meeting.formattedDate, nextRacecardResponse.meeting.track.urlName, nextRacecardResponse.raceNumber, callback);
  }], (error, nextRacecardResponse, nextRaceResponse) => {
    if (error) {
      logger.error('Error fetching next racecard and race: ', error);
      finalCallback(error);
    } else {
      finalCallback(null, nextRacecardResponse, nextRaceResponse);
    }
  });
};
const fetchTurnover = (raceId, userProduct, callback) => {
  const options = {
    method: 'GET',
    path: "/racing/1/race/".concat(raceId, "/turnover?userProduct=").concat(userProduct)
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeTurnover(response.turnover));
    }
  }, error => {
    callback(error);
  });
};
const fetchJackpots = callback => {
  const options = {
    method: 'GET',
    path: "/racing/1/race/jackpots"
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else if (response.jackpots && response.jackpots.length > 0) {
      callback(null, normalizeJackpots(response));
    } else {
      callback(null);
    }
  }, error => {
    callback(error);
  });
};
const fetchMostPlayed = (raceId, userProduct, callback) => {
  const options = {
    method: 'GET',
    path: "/racing/1/race/".concat(raceId, "/mostplayed?userProduct=").concat(userProduct)
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeMostplayed(response.mostplayed));
    }
  }, error => {
    callback(error);
  });
};
const fetchPressRatings = (raceId, callback) => {
  const options = {
    method: 'GET',
    path: "/racing/1/race/".concat(raceId, "/pressratings")
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizePressRatings(response.pressRatings.participantPressRatings));
    }
  }, error => {
    callback(error);
  });
};

const fetchQuinellaOdds = (raceId, subProductId, callback) => {
  let endpoint;
  switch (subProductIdToGameType[subProductId]) {
    case gameTypes.RX2IO:
      endpoint = 'exactaodds';
      break;
    default:
      endpoint = 'quinellaodds';
      break;
  }
  const options = {
    method: 'GET',
    path: "/racing/1/race/".concat(raceId, "/").concat(endpoint)
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeQuinellaOdds(response.race));
    }
  }, error => {
    callback(error);
  });
};
const fetchNextHighFive = function (callback) {
  let includeDefined = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const options = {
    method: 'GET',
    path: "/racing/1/racecard/nexthighfive?includeDefined=".concat(includeDefined ? 'true' : 'false')
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeRacecardNext(response));
    }
  }, error => {
    callback(error);
  });
};
const fetchTips = (raceId, callback) => {
  const options = {
    method: 'GET',
    path: "/racing/1/race/".concat(raceId, "/tips")
  };
  svs.core.jupiter.call(options, response => {
    if (response.error) {
      callback(response.error);
    } else {
      callback(null, normalizeTips(response.tips));
    }
  }, error => {
    callback(error);
  });
};

const getNextRaceDataForProduct = (userProduct, finalCallback) => {
  const searchPath = "/search/1/query?ctx=racing&type=race&includes=payload.race.raceId&andfilter=searchMetadata.tags.userProducts;".concat(userProduct, ",payload.race.raceDrawState;Opened&rangefilter=payload.race.startTimeTentative;gte;now&sort=payload.race.startTimeTentative;asc&count=1");
  const searchOptions = {
    method: 'GET',
    path: searchPath
  };
  const raceOptions = {
    method: 'GET',
    path: ''
  };
  const model = {
    gameTypeAvailable: false,
    error: undefined,
    data: undefined
  };
  async.waterfall([callback => {
    svs.core.jupiter.call(searchOptions, response => {
      if (response.error) {
        callback(response.error);
      } else {
        callback(null, response);
      }
    }, error => {
      callback(error);
    });
  }, (searchResponse, callback) => {
    if (searchResponse && searchResponse.error) {
      callback(searchResponse.error);
    } else if (searchResponse && searchResponse.result && searchResponse.result[0] && searchResponse.result[0].payload && searchResponse.result[0].payload.race) {
      raceOptions.path = "/racing/1/race/".concat(searchResponse.result[0].payload.race.raceId);
      svs.core.jupiter.call(raceOptions, response => {
        if (response.error) {
          callback(response.error);
        } else {
          callback(null, response);
        }
      }, error => {
        callback(error);
      });
    }
  }], (error, raceResponse) => {
    if (error) {
      logger.error('Error fetching next racecard and race: ', error);
      finalCallback(error);
    } else {
      if (raceResponse && raceResponse.race && raceResponse.race.draws) {
        raceResponse.race.draws.forEach(draw => {
          if (draw.userProduct === userProduct && draw.drawState === 'Opened') {
            model.gameTypeAvailable = true;
            model.gameType = draw.userProduct;
          }
        });
      }
      if (model.gameTypeAvailable) {
        model.data = normalizeRace(raceResponse.race);
      }
    }
    finalCallback(null, model);
  });
};
svs.racing.raceService = _objectSpread(_objectSpread({}, svs.racing.raceService), {}, {
  fetchJackpots,
  fetchMostPlayed,
  fetchRacecard,
  fetchRacecardHot,
  fetchRace,
  fetchXpert,
  fetchXpertCustom,
  fetchRacecardNext,
  fetchRaceNext,
  fetchTurnover,
  fetchTips,
  fetchQuinellaOdds,
  multifetchMetaInfo,
  fetchNextHighFive,
  fetchPressRatings,
  getNextRaceDataForProduct
});

 })(window);