(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/normalize.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/normalize.js');


'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  isValidGameType,
  getGameTypeSortOrder
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').util : svs.racing.common.util;
const {
  buildQuinellaOddsLookupKey
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').strings : svs.racing.common.strings;
const {
  oddsTrends,
  infoTypes
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').constants : svs.racing.common.constants;
const {
  getPressRatingTypeName
} = svs.isServer ? require('trinidad-core').core.components.require('racing', 'common').strings : svs.racing.common.strings;
const {
  parseDistance,
  parseDrawState,
  parseGait,
  parseRaceDrawState,
  parseRaceStatus,
  parseGameType,
  parseRaceDiscipline,
  parseStartType,
  parseCurrency,
  parseFinishPlaceReason,
  parseDirection,
  parseTrackCondition
} = svs.isServer ? require('./parse.es6') : svs.racing.raceService.utils.parse;
const participantsMetaProperties = svs.isServer ? require('./participants-meta-fields.es6').participantsMetaProperties : svs.racing.raceService.utils.participantsMetaProperties;
const dateFnsInstance = svs.isServer ? require('trinidad-core').core.components.require('libs', 'date-fns').api : dateFns;
const parseISO = dateFnsInstance.parseISO;
const format = dateFnsInstance.format;
const {
  CurrencyFromJupiterString
} = svs.isServer ? require('trinidad-core').core.components.require('utils', 'format').api : svs.utils.format;
const urlUtils = svs.isServer ? require('trinidad-core').core.components.require('utils', 'url').api : svs.utils.url;

function mapPrizes(prizes) {
  return prizes ? prizes.map(prize => ({
    amount: prize.amount > 0 ? parseCurrency(prize.amount) : prize.amount,
    placement: prize.placement
  })) : undefined;
}
function mapTrack(track) {
  return {
    name: track.trackName,
    urlName: track.pathName
  };
}
function mapWeather(weather) {
  return {
    forecast: weather.forecast,
    temperature: weather.temperature,
    wind: weather.wind
  };
}
function mapMeeting(meeting) {
  return {
    date: meeting.date,
    meetingNumber: meeting.meetingNumber,
    formattedDate: meeting.date ? format(parseISO(meeting.date), 'yyyy-MM-dd') : undefined,
    silksFormattedDate: meeting.date ? format(parseISO(meeting.date), 'yyyyMMdd') : undefined,
    id: meeting.id,
    weather: meeting.weather ? mapWeather(meeting.weather) : undefined,
    track: meeting.track ? mapTrack(meeting.track) : {}
  };
}
function mapDraws(draws) {
  const mappedDraws = draws.map(draw => ({
    gameType: parseGameType(draw),
    drawNumber: draw.drawNumber,
    drawState: parseDrawState(draw.drawState),
    distribution: draw.distribution,
    userProduct: draw.userProduct,
    sortOrder: getGameTypeSortOrder(parseGameType(draw)),
    disabled: draw.disabled
  }));

  return mappedDraws.filter(draw => isValidGameType(draw.gameType)).sort((draw, draw2) => draw.sortOrder - draw2.sortOrder);
}
function mapParticipantResult(participantResult) {
  return participantResult ? {
    finishPlace: parseInt(participantResult.finishPlace, 10),
    finishPlaceReason: parseFinishPlaceReason(participantResult.finishPlaceReason)
  } : undefined;
}
function mapParticipants(participants) {
  let winoddsParticipants = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  winoddsParticipants.forEach(oddsParticipant => {
    oddsParticipant.info = oddsParticipant.info || {};
  });
  return participants.map(participant => ({
    participantId: participant.participantId,
    participantNumber: participant.participantNumber,
    horseName: participant.horseName,
    horseNameUrl: urlUtils.encode(participant.horseName),
    driverName: participant.driverName,
    silks: participant.silks,
    canceled: participant.cancelled,
    participantResult: mapParticipantResult(participant.participantResult),
    additionalDistance: participant.info && participant.info.trottingInfo ? participant.info.trottingInfo.additionalDistance : undefined,
    oddsLatest: winoddsParticipants.filter(oddsParticipant => oddsParticipant.participantNumber === participant.participantNumber)[0] && winoddsParticipants.filter(oddsParticipant => oddsParticipant.participantNumber === participant.participantNumber)[0].info ? winoddsParticipants.filter(oddsParticipant => oddsParticipant.participantNumber === participant.participantNumber)[0].info.odds : undefined
  }));
}
function mapParticipantsOdds(participants) {
  return participants.map(participant => ({
    participantNumber: participant.participantNumber,
    info: {
      odds: CurrencyFromJupiterString(participant.odds),
      refOdds: CurrencyFromJupiterString(participant.refOdds)
    }
  }));
}
function mapParticipantsShowOdds(participants) {
  return participants.map(participant => ({
    participantNumber: participant.participantNumber,
    info: {
      oddsMin: CurrencyFromJupiterString(participant.oddsMin),
      oddsMax: CurrencyFromJupiterString(participant.oddsMax)
    }
  }));
}
function mapDistribution(distributionByPos) {
  return distributionByPos.map(distribution => ({
    position: distribution.position,
    turnover: distribution.turnover,
    percent: distribution.percent ? parseFloat(distribution.percent.replace(/,/g, '.')).toFixed(2) : ''
  }));
}
function mapParticipantsDistribution(distributionParticipants) {
  return distributionParticipants.map(participant => ({
    distributionByPos: mapDistribution(participant.distribution),
    participantNumber: participant.participantNumber
  }));
}
function mapParticipantsQuinellaOdds(participants) {
  const quinellaOdds = {};
  participants.forEach(participant => {
    const key = buildQuinellaOddsLookupKey(participant.firstParticipantNumber, participant.secondParticipantNumber);
    quinellaOdds[key] = CurrencyFromJupiterString(participant.odds);
  });
  return quinellaOdds;
}

function getOddsTrend(oddsLatest, oddsRef) {
  if (isNaN(oddsLatest) || isNaN(oddsRef) || oddsLatest === oddsRef || oddsRef === 0.0) {
    return;
  }
  return oddsLatest > oddsRef ? oddsTrends.UP : oddsTrends.DOWN;
}

function mapParticipantsMeta(race, winodds, distributions, showOdds) {
  const getWinoddsParticipant = participantNumber => {
    if (winodds && winodds.participants) {
      const currentParticipant = winodds.participants.find(oddsParticipant => oddsParticipant.participantNumber === participantNumber);
      return currentParticipant ? currentParticipant.info : {};
    }
    return {};
  };
  const getDistributionParticipant = (distributionUserproducts, participantNumber) => {
    const distributionParticipant = [];
    if (distributions) {
      distributionUserproducts.forEach(userProduct => {
        const distributionByUserproduct = distributions.find(dist => dist.userProduct === userProduct);
        const dist = distributionByUserproduct.participants.find(participant => participant.participantNumber === participantNumber);
        dist.userProduct = userProduct;
        distributionParticipant.push(dist);
      });
      return distributionParticipant;
    }
    return {};
  };
  const getShowOddsParticipant = participantNumber => {
    if (showOdds && showOdds.participants) {
      const currentParticipant = showOdds.participants.find(participant => participant.participantNumber === participantNumber);
      return currentParticipant ? currentParticipant.info : {};
    }
    return {};
  };
  const mergedParticipants = [];
  const oddsLatestTrendMap = new Map();
  const driverWeightMap = new Map();
  const distributionByUserproduct = distributions && Object.keys(distributions).length !== 0 && distributions.find(dist => dist.userProduct === 'Rx1w');
  const highestPercentage = distributionByUserproduct && distributionByUserproduct.participants ? distributionByUserproduct.participants.reduce((max, p) => p.distributionByPos[0].percent > max ? p.distributionByPos[0].percent : max, 0) : 0;
  race.participants.forEach(participant => {
    const {
      odds,
      refOdds
    } = getWinoddsParticipant(participant.participantNumber);
    const distributionUserproducts = distributions && Object.keys(distributions).length !== 0 && distributions.map(distribution => distribution.userProduct);
    const distributionParticipant = distributionUserproducts && getDistributionParticipant(distributionUserproducts, participant.participantNumber);
    const rx1wDistribution = distributionParticipant && distributionParticipant.find(dist => dist.userProduct === 'Rx1w');
    const {
      percent,
      turnover
    } = rx1wDistribution ? rx1wDistribution.distributionByPos[0] : {};
    participant.info = participant.info || {};
    const barCoverage = percent / highestPercentage * 100;
    const currentShowOdds = getShowOddsParticipant(participant.participantNumber);
    const participantDistribution = turnover && percent ? [{
      distributionByPos: [{
        turnover,
        percent,
        barCoverage
      }],
      participantNumber: participant.participantNumber,
      userProduct: 'Rx1w'
    }] : undefined;

    oddsLatestTrendMap.set(participant.participantId, getOddsTrend(odds, refOdds));

    if (participant.info.gallopInfo && participant.info.gallopInfo.driverWeight) {
      driverWeightMap.set(participant.participantId, CurrencyFromJupiterString(participant.info.gallopInfo.driverWeight));
    }
    mergedParticipants.push(_objectSpread(_objectSpread({}, participant), {}, {
      info: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, participant.info), participant.info.gallopInfo), participant.info.trottingInfo), {}, {
        ioDistribution: distributionParticipant,
        oddsLatest: odds,
        oddsRef: refOdds,
        showOdds: currentShowOdds,
        distribution: participantDistribution
      })
    }));
  });
  const columns = [];
  if (mergedParticipants.length > 0 && mergedParticipants[0].info) {
    Object.keys(mergedParticipants[0].info).forEach(key => {
      if (participantsMetaProperties[key]) {
        const metaProps = participantsMetaProperties[key];
        const column = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, metaProps), metaProps.type === infoTypes.ODDS_LATEST && {
          subHeader: winodds.time
        }), metaProps.type === infoTypes.ODDS_REF && {
          subHeader: winodds.refTime
        }), metaProps.type === infoTypes.SHOW_ODDS && {
          subHeader: showOdds.time
        }), metaProps.type === infoTypes.IO_DISTRIBUTION && {
          subHeader: true
        }), {}, {
          key,
          data: []
        });
        columns.push(column);
      }
    });
    columns.sort((a, b) => a.sortOrder - b.sortOrder);
    mergedParticipants.forEach(participant => {
      for (let i = 0; i < columns.length; i++) {
        const currentKey = columns[i].key;
        columns[i].data.push(_objectSpread(_objectSpread({
          participantId: participant.participantId,
          participantNumber: participant.participantNumber,
          value: currentKey === 'driverTotalWeight' ? CurrencyFromJupiterString(participant.info[currentKey]) : participant.info[currentKey]
        }, currentKey === 'oddsLatest' && {
          trend: oddsLatestTrendMap.get(participant.participantId)
        }), currentKey === 'driverTotalWeight' && {
          driverWeight: driverWeightMap.get(participant.participantId)
        }));
      }
    });
  }
  return columns;
}

function normalizeRaceEventHot(raceEvent) {
  return raceEvent ? _objectSpread({
    description: raceEvent.description,
    meeting: mapMeeting(raceEvent.meeting)
  }, normalizeRaceSummary(raceEvent)) : {};
}

function normalizeRaceMeeting(raceMeeting) {
  const meeting = mapMeeting(raceMeeting);
  return _objectSpread(_objectSpread({
    description: raceMeeting.description
  }, meeting), {}, {
    raceSummaries: raceMeeting.raceSummaries.map(raceSummary => _objectSpread(_objectSpread({}, normalizeRaceSummary(raceSummary)), {}, {
      meeting
    }))
  });
}

function normalizeRaceSummary(race, winodds) {
  return _objectSpread({
    countryCode: race.countryCode,
    description: race.description,
    raceId: race.raceId,
    raceNumber: race.raceNumber,
    gait: parseGait(race.gait),
    raceDiscipline: parseRaceDiscipline(race.raceDiscipline),
    raceStatus: parseRaceStatus(race.raceStatus),
    distanceString: parseDistance(race.distance, race.distanceUnit),
    draws: mapDraws(race.draws),
    startTime: race.startTimeTentative,
    startType: parseStartType(race.startType),
    raceDrawState: parseRaceDrawState(race.raceDrawState),
    numberOfParticipants: race.numberOfParticipants
  }, winodds && {
    oddsTime: winodds.oddsTime,
    refOddsTime: winodds.refOddsTime
  });
}

function normalizeRacecard(response) {
  return response.raceCard.meetings.map(normalizeRaceMeeting);
}

function normalizeRacecardHot(response) {
  return response.races.map(normalizeRaceEventHot);
}

function normalizeRacecardNext(response) {
  return normalizeRaceEventHot(response.race);
}

function normalizeRace() {
  let race = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let winodds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let distribution = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let showOdds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  return _objectSpread(_objectSpread({}, normalizeRaceSummary(race, winodds)), {}, {
    meeting: mapMeeting(race.meeting),
    participants: mapParticipants(race.participants, winodds.participants),
    stretchString: parseDistance(race.stretchLength, race.distanceUnit),
    trackWidthString: parseDistance(race.trackWidth, race.distanceUnit),
    proposition: race.proposition,
    participantsMeta: mapParticipantsMeta(race, winodds, distribution, showOdds),
    direction: race.direction ? parseDirection(race.direction) : undefined,
    prizes: mapPrizes(race.prizes),
    trackCondition: race.trackCondition ? parseTrackCondition(race.trackCondition) : undefined,
    raceName: race.raceName,
    trackUuid: race.trackUuid,
    vodAssetId: race.vodAssetId,
    vodAvailableUntil: race.vodAvailableUntil,
    trackFormattedDate: race.meeting.date ? format(parseISO(race.meeting.date), 'yyyyMMdd') : undefined,
    totalPurse: parseCurrency(race.totalPurse),
    currency: race.currency
  });
}
function normalizeWinOdds(race) {
  return {
    raceId: race.raceId,
    time: race.time,
    refTime: race.refTime,
    participants: mapParticipantsOdds(race.participants)
  };
}
function normalizeShowOdds(race) {
  return {
    raceId: race.raceId,
    time: race.time,
    refTime: race.refTime,
    participants: mapParticipantsShowOdds(race.participants)
  };
}
const normalizeWinoddsParticipanstInfo = winodds => _objectSpread(_objectSpread({}, winodds), {}, {
  participants: winodds.participants.map(participant => _objectSpread(_objectSpread({}, participant), {}, {
    info: _objectSpread(_objectSpread({}, participant.info), {}, {
      trend: participant.info.odds > participant.info.refOdds ? 'up' : participant.info.odds < participant.info.refOdds ? 'down' : ''
    })
  }))
});

function normalizeXperten(response) {
  let pixRow = [];
  const {
    positionedParticipants
  } = response.picks[0];
  const isWheel = Boolean(positionedParticipants.base) && Boolean(positionedParticipants.unordered);
  if (isWheel) {
    pixRow[0] = positionedParticipants.base;
    pixRow[1] = positionedParticipants.unordered;
  } else {
    pixRow = Object.values(positionedParticipants);
  }
  const pixReserve = response.picks[0].reserve.participantNumber;
  const expandedRows = [];
  pixRow.forEach((bets, i) => {
    bets.forEach(bet => {
      if (expandedRows.length < 1 || expandedRows.length < i + 1) {
        expandedRows.push([]);
      }
      expandedRows[i].push(bet.participantNumber);
    });
  });
  return {
    row: expandedRows,
    reserve: pixReserve
  };
}

function normalizeMostplayed(mostplayed) {
  return {
    raceId: mostplayed.raceId,
    time: mostplayed.time,
    turnover: parseCurrency(mostplayed.turnover),
    combinations: mostplayed.combinations
  };
}
function normalizeQuinellaOdds(quinellaOdds) {
  return {
    raceId: quinellaOdds.raceId,
    time: quinellaOdds.time,
    refTime: quinellaOdds.refTime,
    turnover: parseCurrency(quinellaOdds.turnover),
    oddsLookup: mapParticipantsQuinellaOdds(quinellaOdds.participants)
  };
}
function normalizePressRatings(pressRatings) {
  return pressRatings.reduce((acc, curr) => {
    if (acc.findIndex(type => type === curr.type) === -1) {
      acc.push(curr.type);
    }
    return acc;
  }, []).reduce((acc, curr) => {
    acc.push({
      title: getPressRatingTypeName(curr),
      rows: pressRatings.filter(rating => rating.type === curr)
    });
    return acc;
  }, []);
}

function normalizeTurnover(turnover) {
  return {
    raceId: turnover.raceId,
    time: turnover.time,
    turnover: parseCurrency(turnover.turnover),
    jackpot: parseCurrency(turnover.jackpot)
  };
}


function mapProductJackpots(jackpot) {
  const mappedProductJackpots = jackpot.map(jp => ({
    userProduct: jp.userProduct.toUpperCase(),
    jackpot: parseCurrency(jp.jackpot)
  }));
  return mappedProductJackpots;
}
function normalizeJackpots(response) {
  const mappedRaceJackpots = response.jackpots.map(racejackpot => ({
    raceId: racejackpot.raceId,
    jackpots: mapProductJackpots(racejackpot.jackpots)
  }));
  return mappedRaceJackpots;
}

function normalizeDistribution(distribution, userProduct) {
  return {
    raceId: distribution.raceId,
    participants: mapParticipantsDistribution(distribution.distributionParticipants),
    userProduct
  };
}


function normalizeTips(response) {
  const mappedTips = response.participantTips.map(tips => ({
    rankOrder: tips.rankOrder,
    participantsNumber: tips.participantNumber
  }));
  return mappedTips;
}
const exported = {
  normalizeDistribution,
  normalizeJackpots,
  normalizeMostplayed,
  normalizeRace,
  normalizeRaceSummary,
  normalizeRacecard,
  normalizeRacecardHot,
  normalizeRacecardNext,
  normalizeShowOdds,
  normalizeWinOdds,
  normalizeWinoddsParticipanstInfo,
  normalizeTips,
  normalizeTurnover,
  normalizeXperten,
  normalizeQuinellaOdds,
  normalizePressRatings
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.raceService.utils.normalize = exported;
}

 })(window);