(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/merge.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/merge.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const findParticipantByNumber = (participants, participantNumber) => participants.find(participant => participant.participantNumber === participantNumber);
const sortMetaField = (meta, sortOrder) => _objectSpread(_objectSpread({}, meta), {}, {
  data: meta.data.slice().sort((m1, m2) => sortOrder.indexOf(m1.participantNumber) - sortOrder.indexOf(m2.participantNumber))
});
const mergeRaceParticipant = raceData => newParticipant => {
  const currentParticipant = findParticipantByNumber(raceData.participants, newParticipant.participantNumber);
  newParticipant.oddsLatest = newParticipant.oddsLatest || currentParticipant.oddsLatest;
  return _objectSpread(_objectSpread({}, currentParticipant), newParticipant);
};
const ignoredMetaFields = ['oddsLatest', 'oddsRef', 'distribution', 'ioDistribution', 'showOdds'];
const mergeRaceParticipantMeta = (raceData, newParticipantNumberSortOrder) => newMeta => {
  const oldMeta = raceData.participantsMeta.find(meta => meta.key === newMeta.key);
  if (ignoredMetaFields.indexOf(newMeta.key) >= 0) {
    return sortMetaField(oldMeta, newParticipantNumberSortOrder);
  }
  return sortMetaField(_objectSpread(_objectSpread({}, oldMeta), {}, {
    data: newMeta.data
  }), newParticipantNumberSortOrder);
};
const mergeWinoddsOddsLatest = (oddsLatest, winodds) => _objectSpread(_objectSpread({}, oddsLatest), {}, {
  subHeader: winodds.time,
  data: oddsLatest.data.map(odds => {
    const winoddsParticipant = findParticipantByNumber(winodds.participants, odds.participantNumber) || {};
    winoddsParticipant.info = winoddsParticipant.info || {};
    return _objectSpread(_objectSpread({}, odds), {}, {
      value: winoddsParticipant.info.odds,
      trend: winoddsParticipant.info.trend
    });
  })
});
const mergeWinoddsOddsRef = (oddsRef, winodds) => _objectSpread(_objectSpread({}, oddsRef), {}, {
  subHeader: winodds.refTime,
  data: oddsRef.data.map(odds => {
    const winoddsParticipant = findParticipantByNumber(winodds.participants, odds.participantNumber) || {};
    winoddsParticipant.info = winoddsParticipant.info || {};
    return _objectSpread(_objectSpread({}, odds), {}, {
      value: winoddsParticipant.info.refOdds
    });
  })
});
const mergeShowodds = (showOddsMeta, showodds) => _objectSpread(_objectSpread({}, showOddsMeta), {}, {
  subHeader: showodds.time,
  data: showOddsMeta.data.map(currentOdds => {
    const showoddsParticipant = findParticipantByNumber(showodds.participants, currentOdds.participantNumber) || {};
    showoddsParticipant.info = showoddsParticipant.info || {};
    return _objectSpread(_objectSpread({}, currentOdds), {}, {
      value: {
        oddsMin: showoddsParticipant.info.oddsMin,
        oddsMax: showoddsParticipant.info.oddsMax
      }
    });
  })
});
const mergeWinoddsParticipant = winodds => participant => _objectSpread(_objectSpread({}, participant), {}, {
  oddsLatest: (winodds.participants.find(winoddsParticipant => winoddsParticipant.participantNumber === participant.participantNumber) || {
    info: {}
  }).info.odds
});
const mergeWinoddsParticipantMeta = winodds => currentMeta => {
  switch (currentMeta.key) {
    case 'oddsLatest':
      return mergeWinoddsOddsLatest(currentMeta, winodds);
    case 'oddsRef':
      return mergeWinoddsOddsRef(currentMeta, winodds);
    default:
      return currentMeta;
  }
};
const mapDistributionValues = (distributionValues, distributionParticipant, maxPercent, distribution) => {
  const newValues = distributionValues.map(value => {
    if (value.userProduct === distribution.userProduct) {
      return {
        distributionByPos: distributionParticipant.distributionByPos.map(dist => ({
          barCoverage: value.userProduct === 'Rx1w' ? Number(dist.percent) / maxPercent * 100 : null,
          position: Number(dist.position),
          percent: Number(dist.percent),
          turnover: dist.turnover
        })),
        participantNumber: distributionParticipant.participantNumber,
        userProduct: distribution.userProduct
      };
    }
    return value;
  });
  return newValues;
};
const mergeDistributionMeta = (distributionMeta, distribution) => {
  if (distributionMeta.key !== 'distribution' && distributionMeta.key !== 'ioDistribution') {
    throw new Error("Meta object is not distribution, actual: ".concat(distributionMeta.key));
  }
  const maxPercent = distributionMeta.key === 'distribution' && Math.max(...distribution.participants.map(participant => Number(participant.distributionByPos[0].percent)));
  return _objectSpread(_objectSpread({}, distributionMeta), {}, {
    data: distributionMeta.data.map(participant => {
      const distributionParticipant = findParticipantByNumber(distribution.participants, participant.participantNumber);
      return _objectSpread(_objectSpread({}, participant), {}, {
        value: participant.value && mapDistributionValues(participant.value, distributionParticipant, maxPercent, distribution)
      });
    })
  });
};
svs.racing.raceService.utils.merge = {
  mergeRaceParticipant,
  mergeRaceParticipantMeta,
  mergeShowodds,
  mergeWinoddsOddsLatest,
  mergeWinoddsOddsRef,
  mergeWinoddsParticipant,
  mergeWinoddsParticipantMeta,
  mergeDistributionMeta
};

 })(window);