(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-service/assets/javascripts/utils/pix-to-coupon.js') >= 0) return;  svs.modules.push('/components/racing/race-service/assets/javascripts/utils/pix-to-coupon.js');
"use strict";


const {
  systemTypes,
  quickpickProps
} = svs.racing.common.constants;
const pixToTopRank = (pixData, pixSize, systemType) => {
  const row = pixData.row; 
  const normalizedRow = new Array(row.length);
  for (let i = 0; i < row.length; i++) {
    for (let k = 0; k < row[i].length; k++) {
      const pixPosition = row[i][k] - 1;
      const previousValue = normalizedRow[pixPosition] ? normalizedRow[pixPosition] : 0;
      const newValue = previousValue | 1 << i;
      normalizedRow[pixPosition] = newValue;
    }
  }
  return {
    row: normalizedRow,
    reserve: pixData.reserve - 1,
    qpValue: systemType === systemTypes.TOP_RANK_PIX ? quickpickProps.PARTIALQP : quickpickProps.WEIGHTEDQP,
    pixSize
  };
};
const pixToUnitCombined = (pixData, pixSize, numberOfParticipants) => {
  const row = pixData.row[0];
  const normalizedRow = new Array(numberOfParticipants).fill(undefined);
  for (let i = 0; i < row.length; i++) {
    const index = row[i] - 1;
    normalizedRow[index] = i + 1;
  }
  return {
    row: normalizedRow,
    reserve: pixData.reserve - 1,
    qpValue: quickpickProps.WEIGHTEDQP,
    pixSize
  };
};
const pixToWheel = (pixData, pixSize, numberOfParticipants) => {
  const [base, unordered] = pixData.row;
  const normalizedRow = new Array(numberOfParticipants).fill(undefined);
  for (let i = 0; i < normalizedRow.length; i++) {
    const participantNumber = i + 1;
    let value;
    if (base.indexOf(participantNumber) !== -1) {
      value = 1;
    } else if (unordered.indexOf(participantNumber) !== -1) {
      value = 2;
    }
    normalizedRow[i] = value;
  }
  return {
    row: normalizedRow,
    reserve: pixData.reserve - 1,
    qpValue: quickpickProps.WEIGHTEDQP,
    pixSize
  };
};
const pixToCoupon = (systemType, pixData, pixSize, numberOfParticipants) => {
  switch (systemType) {
    case systemTypes.UNIT_COMBINED:
    case systemTypes.UNIT_COMBINED_UNORDERED:
      return pixToUnitCombined(pixData, pixSize, numberOfParticipants);
    case systemTypes.TOP_RANK:
    case systemTypes.TOP_RANK_PIX:
      return pixToTopRank(pixData, pixSize, systemType);
    case systemTypes.WHEEL:
      return pixToWheel(pixData, pixSize, numberOfParticipants);
    default:
      return undefined;
  }
};
svs.racing.raceService.utils.pixToCoupon = pixToCoupon;

 })(window);